import { Tweet } from "react-tweet";

const TwitterPost = ({ id }) => {
  return (
    <div className="postBox">
      <Tweet id={id} />
    </div>
  );
};

export default TwitterPost;
