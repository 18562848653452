import "./App.css";
import SocialBar from "./components/Social/Socials";
import Nav from "./components/Nav/nav";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/Homepage/homepage";
import SocialPage from "./components/Social/socialPage";
import AboutPage from "./components/About/aboutPage";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route exact path="/" Component={HomePage} />
        <Route path="/social" Component={SocialPage} />
        <Route path="/about" Component={AboutPage} />
      </Routes>
      <SocialBar />
    </div>
  );
}

export default App;
