import tIcon from "../../../imgs/twitter32.png";
import "./team.css";

const TeamCard = ({ person }) => {
  return (
    <div className="teamCard">
      <img className="cardIcon" src={person.tImg} />
      <h2>{person.name}</h2>
      <a href={person.twLink}>
        <img src={tIcon} />
      </a>
    </div>
  );
};

export default TeamCard;
