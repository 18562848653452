import "./aboutStyles.css";
import TeamPanel from "./Team/teamPanel";
import CatPanel from "./Cat/theCatHimself";
import MisPanel from "./Mission/mission";

const AboutPage = () => {
  return (
    <div className="aboutCont">
      <TeamPanel />
      <CatPanel />
      <MisPanel />
    </div>
  );
};

export default AboutPage;
