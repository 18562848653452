import "../../styles/navStyles.css";
import xIcon from "../../imgs/twitter32.png";
import tIcon from "../../imgs/telegram32.png";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div className="navCont">
      <div className="HeaderCont">
        <div className="headNavCont">
          <div className="twIconCont">
            <a href="https://twitter.com/ItsOverCat">
              <img src={xIcon} />
            </a>
          </div>
          <div className="navLinks">
            <Link to="/">
              <a>Home</a>
            </Link>
            <Link to="/about">
              <a>About</a>
            </Link>
            {/* <Link to="/social">
              <a>Social</a>
            </Link> */}
          </div>
          <div className="tgIconCont">
            <a href="https://t.me/itssoovercat">
              <img src={tIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
