import "./airDropStyles.css";
import TwitterPost from "../Social/twitterPost";

const AirDropBox = () => {
  return (
    <div className="airDropBox">
      <h2>Airdrop Coming</h2>
      <div>
        <TwitterPost id="1786709854544875569" />
      </div>
    </div>
  );
};

export default AirDropBox;
