import "./roadmapStyles.css";
import arrow from "./arrow-199-48.png";

const RoadMap = () => {
  return (
    <div className="roadmapBox">
      <h2>Roadmap</h2>
      <div className="roadmap">
        <div className="smoke"></div>
        <h2>Pump.fun launch</h2>
        <img src={arrow} />
        <h2>Hit Raydium</h2>
        <img src={arrow} />
        <h2>Dexscreener update</h2>
        <img src={arrow} />
        <h1>Token airdrop</h1>
        <img src={arrow} />
        <h2>Token burn milestone</h2>
        <img src={arrow} />
        <h1>World take $OVER</h1>
      </div>
    </div>
  );
};

export default RoadMap;
