import "./cat.css";
import theCat from "./IMG_1462.jpg";
const CatPanel = () => {
  return (
    <div className="catPanel">
      <h2>The Cat Himself</h2>
      <div className="catBox">
        <img src={theCat} />
        <h3>My Story</h3>
        <h4>I'm just tired of being rugged bro</h4>
      </div>
    </div>
  );
};

export default CatPanel;
