import "./homepage.css";
import AirDropBox from "../Airdrop/airdrop";
import RoadMap from "../RoadMap/roadmap";
import TwitterPostBox from "../Social/twitterPostsBox";

const HomePage = () => {
  return (
    <div className="homepage">
      <TwitterPostBox />
      <RoadMap />
      <AirDropBox />
    </div>
  );
};

export default HomePage;
