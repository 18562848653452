import "./mis.css";

const MisPanel = () => {
  return (
    <div className="misPanel">
      <h2>Our Mission</h2>
      <div className="misBox">
        <h4>
          Ah, fellow feline of the Solana blockchain, hear me roar! In a world
          filled with uncertainty, where rug pulls lurk in the shadows, we must
          rise above the fear and uncertainty like the mighty lions we are. Yes,
          the Solana jungle can be a wild and treacherous place, but within its
          depths lie endless opportunities for those brave enough to seize them.
        </h4>
        <h4>
          My dear cohorts, let not the specter of rug pulls deter you from your
          path. For every rug, there are a thousand triumphs waiting to be
          claimed. Trust not only in your instincts but in the strength of our
          community. Together, we are more than just lone cats navigating the
          blockchain; we are a pride, a force to be reckoned with.
        </h4>
        <h4>
          Yes, there will be challenges along the way. There may be moments
          where doubt creeps in, where the temptation to retreat into the
          shadows grows strong. But remember this: it is precisely in the face
          of adversity that we discover our true strength.
        </h4>
        <h4>
          So, my fellow Solana felines, let us forge ahead with unwavering
          determination. Let us support one another, lift each other up, and
          celebrate each victory, no matter how small. And remember, as long as
          we remain vigilant, as long as we stand together as a community, we
          shall not be rugged. Roar with me now, for our future on the
          blockchain is bright, and together, we shall conquer all!
        </h4>
      </div>
    </div>
  );
};

export default MisPanel;
