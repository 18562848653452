import "../../styles/twitterStylers.css";
import TwitterPost from "./twitterPost";

const TwitterPostBox = () => {
  return (
    <div className="twitterBox">
      <h2 className="font-use-1">Top Tweets</h2>
      <div className="twitterPostBox">
        <TwitterPost id="1786649567397552539" />
        <TwitterPost id="1786668331677987302" />
        <TwitterPost id="1787279165491658971" />
        <TwitterPost id="1786753636359377277" />
        <TwitterPost id="1787417100694303005" />
      </div>
    </div>
  );
};

export default TwitterPostBox;
