import xIcon from "../../imgs/twitter32.png";
import tIcon from "../../imgs/telegram32.png";
import "../../styles/socialStyles.css";

const SocialBar = () => {
  return (
    <div className="social">
      <a href="https://twitter.com/ItsOverCat">
        <img src={xIcon} />
      </a>
      <a href="https://t.me/itssoovercat">
        <img src={tIcon} />
      </a>
    </div>
  );
};

export default SocialBar;
