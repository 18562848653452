import "./team.css";
import teamList from "./teamList";
import TeamCard from "./teamCard";

const TeamPanel = () => {
  return (
    <div className="teamPanel">
      <h2>Team</h2>
      <div className="teamBox">
        <div className="devTeam">
          <h3>Dev</h3>
          <div>
            <TeamCard person={teamList.dev.abundo} />
          </div>
        </div>
        <div className="markTeam">
          {/* <h3>Marketing</h3>
          <div>
            <TeamCard person={teamList.marketing.zoro} />
          </div> */}
        </div>
        <div className="memeTeam">
          <h3>Meme Guy</h3>
          <div>
            <TeamCard person={teamList.meme.az} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPanel;
