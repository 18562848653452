const teamList = {
  dev: {
    abundo: {
      twLink: "https://twitter.com/AbundantShit",
      name: "Abundo",
      tImg: "https://pbs.twimg.com/profile_images/1784460804210991104/LS2NowIF_400x400.jpg",
    },
  },
  marketing: {
    zoro: {
      twLink: "https://twitter.com/MerchantZoro",
      name: "Zoro",
      tImg: "https://pbs.twimg.com/profile_images/1724456076525903872/zmLHm-L4_400x400.jpg",
    },
  },
  meme: {
    az: {
      twLink: "https://twitter.com/kakashi9k_",
      name: "OxAzrael",
      tImg: "https://pbs.twimg.com/profile_images/1779618521787322368/D4Cr_KW__400x400.jpg",
    },
  },
};

export default teamList;
